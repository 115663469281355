import antd from 'ant-design-vue/es/locale-provider/ru_RU'
import momentCN from 'moment/locale/ru'
import setting from './ru-RU/setting'

const components = {
  antLocale: antd,
  momentName: 'ru-ru',
  momentLocale: momentCN
}

export default {
  'message': '-',

  ...components,
  ...setting
}
