import request from '@/utils/request'


//数据字典
export function getDict() {
  return request({
    url: 'dict/dict',
    method: 'post'
  })

}
export function fileUpload(data) {
  return request({
    url: 'upload/file',
    method: 'post',
    data: data
  })
}

export function translation(data) {
  return request({
    url: '/common/translation',
    method: 'post',
    data: data
  })
}

// 获取临时token
export function getSTSToken() {
  return request({
    url: '/common/oss',
    headers:{"repeatSubmit": false},
    method: 'post'
  })
}