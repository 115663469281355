export default {
  'app.setting.pagestyle':'Общая настройка стиля',
  'app.setting.pagestyle.light':'Яркий стиль меню',
  'app.setting.pagestyle.dark':'Темный стиль меню',
  'app.setting.pagestyle.realdark':'Темный режим',
  'app.setting.themecolor':'Цвет темы',
  'app.setting.navigationmode':'Режим навигации',
  'app.setting.content-width':'Ширина области контента',
  'app.setting.fixedheader':'Фиксированный Header',
  'app.setting.fixedsidebar':'Фиксированная боковая панель',
  'app.setting.multitab':'Показать подпись страницы',
  'app.setting.sidemenu':'Макет бокового меню',
  'app.setting.topmenu':'Верхний макет меню',
  'app.setting.content-width.fixed':'Исправлено',
  'app.setting.content-width.fluid':'Жидкость',
  'app.setting.othersettings':'Другие настройки',
  'app.setting.weakmode':'Режим цветовой слабости',
  'app.setting.copy':'Настройки копирования',
  'app.setting.loading':'Загрузка темы',
  'app.setting.copyinfo':'Скопировать настройки успешно src/config/defaultSettings.js',
  'app.setting.production.hint':'Панель конфигурации используется только для предварительного просмотра в среде разработки, производственная среда не отображается, пожалуйста, вручную измените файл конфигурации после копирования',
  'app.setting.themecolor.daybreak':'Рассвет синий',
  'app.setting.themecolor.dust':'Сумерки',
  'app.setting.themecolor.volcano':'Вулкан',
  'app.setting.themecolor.sunset':'Сумерки',
  'app.setting.themecolor.cyan':'Мин Цин',
  'app.setting.themecolor.green':'Аврора зеленый',
  'app.setting.themecolor.geekblue':'Гики синий',
  'app.setting.themecolor.purple':'Соус фиолетовый',

  '系统用户': {
    '开始时间':'Время начала',
    '结束时间':'Время окончания',
  },

  '通用': {
    '按钮': {
      '新增':'Добавлено',
      '确定':'Определить',
      '审核':'Аудит',
      '修改':'Модификация',
      '复制':'Копирование',
      '删除':'Удалить',
      '关闭':'Закрыть',
      '导出':'Экспорт',
      '选中导出':'Выберите экспорт',
      '导入':'Импорт',
      '查询':'Запрос',
      '重置':'Сброс',
      '详情':'Подробная информация',
      '上传':'Загрузка',
      '展开':'Развернуть',
      '收起':'Убей',
      '更多':'Больше',
      '保存':'Сохранить',
      '取消':'Отмена',
      '翻译':'Переводчик',
      '刷新缓存':'Обновить кэш',
      '详情及审核':'Детали и обзор',
      '客服':'Обслуживание клиентов',
      '平台客服':'Платформа обслуживания клиентов',
    },
    '输入':{
      '请输入':'Пожалуйста, введите',
      '请选择':'Пожалуйста, выберите',
    },
    '文本': {
      '是':'Да, да.',
      '否':'Нет',
      '启用':'Включение',
      '禁用':'Отключить',
      '操作':'Операция',
      '添加':'Добавить',
      '创建时间':'Время создания',
      '排序':'Сортировка',
      '修改':'Модификация',
      '详情':'Подробная информация',
      '确认删除所选中数据':'Подтвердите удалить выбранные данные?',
      '删除成功':'Удаление прошло успешно',
      '操作成功': 'Операция прошла успешно',
      '确认取消':'Подтвердите отмену?',
      '取消成功':'Отмена прошла успешно',
      '新增成功':'Новый успех',
      '修改成功':'Модификация прошла успешно',
      '上传成功':'Успешная загрузка',
      '共':'Всего',
      '条':'Полоска',
      '默认':'По умолчанию',
      '中等':'Средний',
      '紧凑':'Компактный',
      '列展示排序':'Сортировка показа столбцов',
      '列排序需拖动名称':'Сортировка столбцов требует перетаскивания имени',
      '不能为空':'Не может быть пустым',
      '不能小于0':'Не может быть меньше 0',
      '多语言配置':'Многоязычная конфигурация',
      '语言':'Язык',
      '根节点':'Корневой узел',
      '创建开始时间':'Создание времени начала',
      '创建结束时间':'Создание времени окончания',
      '选择商品':'Выбор товаров',
      '详情及审核':'Детали и обзор',
      '确认核销':'Подтвердите списание?',
      '核销成功':'Успешное списание',
      '是否确认导出':'Вы подтверждаете экспорт?',
      '此操作将导出当前条件下所有数据而非选中数据':'Эта операция экспортирует все данные в текущих условиях вместо выбранных данных',
      '此操作将导出选中数据':'Эта операция будет экспортировать выбранные данные',
    },
  },
  '广告': {
    '位置':'Расположение',
    '跳转类型':'Тип прыжка',
    '广告名称':'Название объявления',
    '开始时间':'Время начала',
    '结束时间':'Время окончания',
    '选择申请':'Выбор заявки',
    '外链':'Внешняя цепь',
    '内容详情':'Подробная информация о содержании',
    '是否隐藏':'Скрыть или нет',
    '展示时长':'Продолжительность показа',
    '展示次数':'Количество показов',
    '点击次数':'Количество кликов',
    '跳转次数':'Количество прыжков',
    '封面图':'Рисунок обложки',
    '详情图':'Подробная диаграмма',
  },

  '商品分类': {
    '商品分类':'Классификация товаров',
    '分类名称':'Название классификации',
    '分类图片':'Категорические картинки',
    '上级分类':'Высшая классификация',
    '分类等级':'Классификация',
    '是否显示':'Отображать ли',
    '是否首页展示':'Будет ли отображаться домашняя страница',
    '是否参与筛选':'Участие в скрининге',
    '创建时间':'Время создания',
    '分类类型': 'Тип классификации',
    '国内': 'Внутренние',
    '跨境': 'Трансграничные границы',
  },
  '商品标签':{
    '标签名称':'Название этикетки',
    '图标':'Значок',
    '颜色':'Цвет',
    '状态':'Состояние',
    '创建时间':'Время создания',
    '更新时间':'Время обновления',
    '备注':'Примечания',
  },
  '商品': {
    '套餐':'Пакет',
    '标签':'Этикетки',
    '备注':'Примечания',
    '售卖区域':'Зона продажи',
    '批量上架':'Пакет на полках',
    '批量下架':'Сниз партии',
    '批量复制':'Массовое копирование',
    '确认复制':'Подтвердите копирование?',
    '复制成功':'Успешное копирование',
    '选择明星':'Выбор звезды',
    '选择运费模板':'Выбор шаблона оплаты',
    '基础信息':'Базовое сообщение',
    '标签信息':'Информация о маркировке',
    '规格信息':'Информация о спецификациях',
    '详情信息':'Подробная информация',
    '上架成功':'Успех на полках',
    '下架成功':'Сниз успешно',
    '确认上架':'Подтвердите?',
    '确认下架':'Подтвердите, что с полок?',
    '添加规格':'Добавить спецификации',
    '修改规格':'Изменение спецификации',
    '商品类型':'Типы товаров',
    '商品':'Товары',
    '明星':'Звезда',
    '限购数':'Ограничение покупки',
    '售卖开始时间':'Время начала продаж',
    '售卖结束时间':'Время окончания продажи',
    '是否多规格':'Есть ли несколько спецификаций',
    '是否允许合并购买':'Разрешить ли консолидированную покупку',
    '商品颜色':'Цвет товара',
    '商品详情':'Детали товара',
    '市场价':'Рыночная цена (черченные цены)',
    '成本价':'Себестоимости',
    '虚拟销量':'Виртуальные продажи',
    '是否展示销量':'Показан ли объем продаж',
    '商品价':'Цены на товары',
    '商品编号':'Номер товара',
    'SKU编号':'Номер SKU',
    'SKU名字':'Название SKU',
    '规格名称':'Название спецификации',
    '商品名称':'Название товара',
    '商品重量':'Вес товара',
    '包装单位':'Единица упаковки',
    '商品主图':'Основная карта товара',
    '商品视频':'Видео товара',
    '商品轮播图':'Карта ротации товаров',
    '商品分类':'Классификация товаров',
    '跨境商品分类': 'Классификация трансграничных товаров',
    '国家': 'Страна',
    '商品关键词':'Товарные ключевые слова',
    '品牌':'Бренд',
    '运费模板':'Шаблон оплаты',
    '商品价格':'Цены на товары',
    '活动价格':'Цены на мероприятия',
    '秒杀价格':'Спайк цена',
    '团购价格':'Цена групповой покупки',
    '会员价格':'Членские цены',
    '限购数量':'Ограниченное количество покупки',
    '商品库存':'Товарный инвентарь',
    '预警库存':'Запасы раннего предупреждения',
    '商品状态':'Статус товара',
    '实际销量':'Фактический объем продаж',
    '售卖范围':'Диапазон продаж',
    '是否推荐':'Рекомендуется ли',
    '是否新品':'Является ли новый продукт',
    '是否样品':'Является ли образец',
    '是否日历展示':'Будет ли отображаться календарь',
    '是否精品':'Является ли это бутик',
    '是否免费领':'Является ли бесплатный воротник',
    '是否限购':'Ограничить покупку',
    '是否爆款':'Взрывные деньги',
    '售后方式':'Послепродажный метод',
    '创建时间':'Время создания',
    '规格': {
      '添加规格值':'Добавить значение спецификации',
      '规格名称':'Название спецификации',
      '规格值':'Значение спецификации',
      '是否可见':'Виден ли',
      '规格图片':'Спецификация изображения',
      '是否有规格图片':'Есть ли изображение спецификации',
    },
    'sku': {
      '名称':'Название',
      '编码':'Номер',
      '价格':'Цена',
      '活动价格':'Цены на мероприятия',
      '库存':'Инвентаризация',
      '重量':'Вес',
      '图片':'Картинки',
    },
  },
  '品牌': {
    '品牌名称':'Название бренда',
    '品牌备注':'Замечания бренда',
    '品牌首字母':'Инициалы бренда',
    'logo':'Логотип',
    '创建时间':'Время создания',
  },
  '明星分类': {
    '分类名称':'Название классификации',
    '是否显示':'Отображать ли',
    '创建时间':'Время создания',
    '备注':'Примечания',
  },
  '明星': {
    '明星名字':'Звёздное имя',
    '明星分类':'Классификация звезд',
    '明星照片':'Фотографии звезд',
    '背景图':'Фоновая иллюстрация',
    '网址':'Веб-сайт',
    '状态':'Состояние',
    '基础粉丝数':'Базовое количество поклонников',
    '关注数':'Количество проблем',
    '明星介绍':'Звезда знакомство',
    '创建时间':'Время создания',
    '备注':'Примечания',
  },
  '明星俱乐部': {
    '俱乐部名字':'Название клуба',
    '商品数':'Количество товаров',
    '创建时间':'Время создания',
    '备注':'Примечания',
  },

  '活动分类':{
    '分类名称':'Название классификации',
    '状态':'Состояние',
    '排序':'Сортировка',
    '创建者':'Создатель',
    '创建时间':'Время создания',
    '修改人':'Изменить лицо',
    '更新时间':'Время обновления',
    '备注':'Примечания',
    '首页图片':'Главная картинка',
  },
  '活动记录': {
    '活动编号':'Номер деятельности',
    '商品最大购买数量':'Максимальное количество покупки товара',
    '排行':'Рейтинги',
    '选择活动':'Выбор деятельности',
    '是否上架':'Является ли это на полках',
    '活动类型':'Виды деятельности',
    '活动名称':'Название мероприятия',
    '开始时间':'Время начала',
    '结束时间':'Время окончания',
    '活动封面图':'Обложка мероприятия',
    '分享海报图':'Поделиться иллюстрация плаката',
    '分享文案':'Обмен копирайтинга',
    '限制会员等级':'Ограничение уровня членства',
    '最多参与次数':'Максимальное участие',
    '活动详情':'Подробная информация о мероприятии',
    '状态':'Состояние',
    '是否展示到日历':'Показан ли в календаре',

    '创建时间':'Время создания',
    '活动参与区域':'Зона участия в мероприятиях',
  },
  '运费模板': {
    '计费方式':'Биллинг',
  },
  '收货地址': {
    '应援地址':'Адрес помощи',
    '收货区域':'Зона получения товара',
    '收货人手机号':'Номер мобильного телефона получателя',

  },
  '广告投放审核': {
    '联系电话':'Контактный телефон',
    '广告名称':'Название объявления',
    '广告简介':'Введение в рекламу',
    '广告活动开始时间':'Время начала',
    '广告活动结束时间':'Время окончания',
    '审核状态':'Статус аудита',
    '审核时间':'Время рассмотрения',
    '姓名':'Имя',
    '创建时间':'Время создания',
    '原因':'Причина',
    '是否通过':'Проследует ли',

  },
  '订单取消原因': {
    '名称':'Название',
    '类型':'Тип',
    '创建时间':'Время создания',
    '是否显示':'Отображать ли',
  },

  '等级设置': {
    '会员等级名称':'Название уровня',
    '会员等级卡片名称': 'Название карточки',
    '会员等级':'Уровень членства',
    '升级所需经验':'Опыт, необходимый для повышения в должности',
    '等级图片':'Изображение уровня',
    '等级图标':'Значок уровня',
    '会员等级排序':'Рейтинг членства',
    '升级所需积分':'Баллы, необходимые для обновления',
  },
  '商品足迹': {
    '商品名称':'Название товара',
    '商品主图':'Основная карта товара',
    '排序':'Сортировка',
    '搜索次数':'Количество поисков',
    '创建时间':'Время создания',
  },
  '订单发货记录': {
    '发货方式':'Способ доставки',
    '发货人手机号':'Номер мобильного телефона грузоотправителя',
    '发货时间':'Время доставки',
    '发货记录编号':'Номер записи отгрузки',
    '发货开始时间':'Время начала отгрузки',
    '发货结束时间':'Время окончания отгрузки',

  },

  '销售订单': {
    '全部订单':'Все заказы',
    '此操作将导出当所有待发货订单而非选中数据':'Эта операция будет экспортировать все заказы, которые будут отправлены вместо выбранных данных',
    '导出待发货':'Экспорт в ожидании отправки',
    '导入发货单':'Импорт накладной',
    '接单':'Принимать заказы',
    '接单成功':'Успешный заказ',
    '物流公司':'Логистические компании',
    '物流单号':'Номер логистического заказа',
    '物流详情':'Подробная информация о логистике',
    '寄件人手机号':'Номер мобильного телефона отправителя',
    '订单编号':'Номер заказа',
    '订单类型':'Тип заказа',
    '订单来源':'Источник заказа',
    '平台币抵扣':'Платежный кредит',
    '订单数量':'Количество заказа',
    '订单信息':'Информация о заказе',
    '订单详情':'Детали заказа',
    '三方订单号':'Номер трехстороннего заказа',
    '买家名称':'Название покупателя',
    '买家信息':'Информация о покупателе',
    '手机号或邮箱':'Номер мобильного телефона или почтовый ящик',
    '订单状态':'Статус заказа',
    '下单时间':'Время заказа',
    '下单开始时间':'Время начала заказа',
    '下单结束时间':'Время окончания заказа',
    '支付方式':'Способ оплаты',
    '支付时间':'Время оплаты',
    '退款状态':'Статус возврата',
    '商品数量':'Количество товаров',
    '商品金额':'Сумма товара',
    '商品实付金额':'Товар оплачивается фактическая сумма',
    '优惠金额':'Сумма скидки',
    '平台抽成':'Платформа вытягивается',
    '订单金额':'Сумма заказа',
    '运费金额':'Сумма фрахта',
    '运费实付':'Фактическая оплата фрахта',
    '用户备注':'Замечания пользователя',
    '商品信息':'Информация о товаре',
    '活动信息':'Информация о деятельности',
    '收货地址信息':'Информация об адресе доставки',
    '实付单价':'Платная цена за единицу',
    '数量':'Количество',
    '收货人姓名':'Имя получателя',
    '性别':'Пол',
    '手机号':'Номер мобильного телефона',
    '邮箱':'Почтовый ящик',
    '地址类型':'Тип адреса',
    '地区':'Региона',
    '详细地址':'Подробные адреса',
    '发货':'Доставка',
    '发货成功':'Успешная доставка',
    '取消订单':'Отмена заказа',
    '核销订单':'Заказы на списание',
    '发货备注':'Примечания к отгрузке',
    '平台币':'Платформа',
    '订单原价':'Первоначальная цена заказа',
    '实付金额':'Оплаченная сумма',
    '运费':'Фрахт',
    '是否首单':'Является ли первый заказ',
    '是否催发货':'Сжать ли товар',

    '预约日期':'Дата назначения',
    '预约开始时间':'Время начала',
    '预约结束时间':'Время окончания',
    '是否需要预约':'Нужно ли бронировать',
    '售卖结束时间':'Время окончания продажи',
    '核销码':'Код списания',
    '套餐信息':'Информация о пакете',
    '人员信息':'Информация о персонаже',

  },
  '售后订单': {
    '原订单编号':'Оригинальный номер заказа',
    '售后单编号':'Номер заказа после продажи',
    '售后类型':'Тип послепродажного обслуживания',
    '售后状态':'Послепродажное состояние',
    '售后凭证':'Послепродажный ваучер',
    '购买数量':'Количество покупок',
    '售后数量':'Послепродажное количество',
    '申请开始时间':'Время начала подачи заявки',
    '申请结束时间':'Время окончания подачи заявки',
    '退款金额':'Сумма возврата',
    '申请时间':'Время подачи заявки',
    '售后原因':'Причины послепродажного обслуживания',
    '售后信息':'Послепродажная информация',
    '原订单信息':'Оригинальная информация о заказе',
    '订单详情':'Детали заказа',
    '售后详情':'Послепродажная информация',
    '售后商品':'Товары после продажи',
    '备注':'Примечания',
    '审核':'Аудит',
    '是否同意':'Согласитесь или нет',
    '拒绝原因':'Причины отказа',
    '审核成功':'Проверка прошла успешно',
    '退款':'Возврат средств',
    '是否确认退款':'Подтверждено ли возмещение',
    '退款成功':'Возврат средств был успешным',
    '收货地址':'Адрес доставки',


  },

  '用户': {
    '发送消息':'Отправить сообщение',
    '手机号':'Номер мобильного телефона',
    '联系方式':'Контакты',
    '用户等级':'Уровень',
    '邮箱':'Почтовый ящик',
    '昵称':'Прозвище',
    '微信':'WeChat',
    '积分':'Интеграл',

    '真实姓名':'Настоящее имя',
    '身份证':'Удостоверение личности',
    '头像':'Аватар',
    '性别':'Пол',
    '生日':'День рождения',
    '邀请码':'Код приглашения',
    '注册时间':'Время регистрации',
    '用户状态':'Состояние пользователя',
  },
  '用户积分变动记录':{
    '订单编号':'Номер заказа',
    '备注':'Примечания',
    '变动的积分数':'Количество переменных баллов',
    '变动前剩余积分':'Оставшиеся баллы до изменения',
    '变动后剩余积分':'Оставшиеся баллы после изменения',
  },


  '用户搜索记录': {
    '类型':'Тип',
    '搜索内容':'Поиск контента',
    '搜索次数':'Количество поисков',
    '创建时间':'Время создания',
  },

  '用户注销': {
    '注销原因':'Причины списания',
    '审核状态':'Статус аудита',
    '审核失败原因':'Причины сбоя аудита',
    '审核人':'Ревизионный',
    '审核时间':'Время рассмотрения',
    '注销时间':'Время отмены',
  },

  '意见反馈': {
    '用户':'Пользователи',
    '处理状态':'Состояние обработки',
    '备注':'Примечания',
    '处理':'Обработка',
    '反馈图片':'Фотографии обратной связи',
    '联系方式':'Контакты',
    '反馈类型':'Тип обратной связи',
    '反馈内容':'Содержание обратной связи',
    '处理结果':'Обработка результатов',
    '创建时间':'Время создания',
  },

  '物流公司信息': {
    '编号':'Номер',
    '公司名称':'Название компании',
    '公司编码':'Код компании',
    '公司类型':'Тип компании',
    '公司logo':'Логотип компании',
    '是否选用':'Выбираете ли',
    '排序':'Сортировка',
    '备注':'Примечания',

  },
  '报表': {
    '商品': {
      '订单开始时间':'Время начала заказа',
      '订单结束时间':'Время окончания заказа',
      '销售占比':'Доля продаж',
      '退款占比':'Доля возврата',
      '点击量':'Количество кликов',
      '收藏量':'Коллекция',
      '订单量':'Объем заказа',
      '商品销量':'Товарные продажи',
      '加购量':'Дополнительный объем покупки',
      '商品销售额':'Товарные продажи',
      '订单销售金额':'Сумма продаж заказа',
      '销售金额':'Сумма продаж',
      '退款金额':'Сумма возврата',
      '退款单数':'Количество возвратов',
      '退款量':'Сумма возврата',
    },
    '用户': {
      '注册开始时间':'Время начала регистрации',
      '注册结束时间':'Время окончания регистрации',
      '支付金额':'Сумма платежа',
      '最后下单时间':'Время окончательного заказа',
      '购买商品量':'Количество приобретенных товаров',
    },
  },


  '商品对账单': {
    '账单日期':'Дата выставления счета',
    '账单月份':'Месяц выставления счетов',

  },
  '交易单':{
    '交易单号':'Номер торгового заказа',
    '订单编号':'Номер заказа',
    '订单类型':'Тип заказа',
    'ip':'IP',
    '支付方式':'Способ оплаты',
    '交易类型':'Тип сделки',
    '支付金额':'Сумма платежа',
    '账期金额':'Сумма периода',
    '手续费':'Плата за обработку',
    '支付信息':'Информация об оплате',
    '三方订单号':'Номер трехстороннего заказа',
    '三方支付流水号':'Трехсторонний платежный номер',
    '支付状态':'Статус платежа',
    '交易完成时间':'Время завершения сделки',
    '创建时间':'Время создания',
    '过期时间':'Срок годности',
    '备注':'Примечания',
  },
  '常见问题':{
    '常见问题标题':'Часто задаваемые вопросы',
    '常见问题简述':'Краткое описание часто задаваемых вопросов',
    '问题分类':'Классификация вопросов',
    '常见问题内容':'Часто задаваемые вопросы',
    '显示顺序':'Порядок отображения',
    '状态':'Состояние',
    '备注':'Примечания',
    '创建人':'Создатель',
    '插入时间':'Время вставки',
    '修改人':'Изменить лицо',
    '修改时间':'Время изменения',
    '版本号':'Номер версии',
  },
  '常见问题分类':{
    '主图':'Главная диаграмма',
    '标题':'Заголовок',
    '显示顺序':'Порядок отображения',
    '备注':'Примечания',
    '创建人':'Создатель',
    '插入时间':'Время вставки',
    '修改人':'Изменить лицо',
    '修改时间':'Время изменения',
    '版本号':'Номер версии',
  },
  '店铺分类': {
    '店铺分类':'Классификация магазина',
    '分类名称':'Название классификации',
    '分类图片':'Категорические картинки',
    '上级分类':'Высшая классификация',
    '分类等级':'Классификация',
    '是否显示':'Отображать ли',
    '是否首页展示':'Будет ли отображаться домашняя страница',
    '是否参与筛选':'Участие в скрининге',
    '创建时间':'Время создания',
  },
  '入驻时长': {
    '入驻时长（年）':'Длительное время (лет)',
    '分类名称':'Название классификации',
    '分类图片':'Категорические картинки',
    '上级分类':'Высшая классификация',
    '分类等级':'Классификация',
    '是否显示':'Отображать ли',
    '是否首页展示':'Будет ли отображаться домашняя страница',
    '是否参与筛选':'Участие в скрининге',
    '创建时间':'Время создания',
  },
  '主营行业': {
    '分类名称':'Название отрасли',
    '创建时间':'Время создания',
  },
  '店铺': {
    '店铺名称':'Название магазина',
    '店铺logo':'Логотип',
    '店铺背景图':'Фоновая иллюстрация',
    '客服电话':'Телефон службы поддержки клиентов',
    '入驻时长':'Длительное время (лет)',
    '入驻类型':'Тип въезда',
    '店铺类型': 'Тип магазина',
    '是否推荐':'Рекомендуется ли',
    '账户余额':'Остаток на счете',
    '已结算金额':'Расчетная сумма',
    '店铺状态':'Состояние магазина',
    '关键词':'Ключевые слова',
    '营业时间':'Часы работы',
    '店铺分类':'Классификация магазина',
    '基础信息':'Базовое сообщение',
    '主营行业':'Основная отрасль',
    '地址信息':'Информация об адресе',
    '店铺所在省份':'Провинции',
    '店铺所在城市':'Города',
    '店铺所在县':'Округ',
    '经度':'Долгота',
    '纬度':'Широта',
    '详细地区':'Подробные адреса',
    '店铺简介':'Введение в магазин',
    '公司申请类型':'Тип приложения',
    '店铺时间':'Время создания',
    '入驻资料':'Информация о поселении',
    '法人姓名':'Имя юридического лица',
    '法人手机号':'Номер мобильного телефона юридического лица',
    '法人邮箱':'Почтовый ящик юридического лица',
    '法人身份证号':'Идентификационный номер юридического лица',
    '身份证正面':'Лицевая сторона удостоверения личности',
    '身份证反面':'Обратная сторона удостоверения личности',
    '营业执照':'Лицензия на ведение бизнеса',
    '真实姓名':'Настоящее имя',
    '结算账户':'Расчетный счет',
    '银行预留手机号':'Банк резервирует номер мобильного телефона',
    '结算银行开户名':'Открытие банковского счета',
    '结算开户银行支行名称':'Название отделения банка',
    '结算开户银行所在地':'Место нахождения депозитарного банка',
    '审核信息':'Аудит информации',
    '保证金信息':'Информация о марже',
    '保证金':'Маржа',
    '打款备注':'Примечание',
    '打款截图':'Скриншоты',
    '店铺状态是否开启':'Открыт ли статус магазина',
  },
  '账户类型': {
    '账户类型名称':'Название типа счета',
    '创建时间':'Время создания',
  },
  '店铺标签': {
    '标签名称':'Название этикетки',
    '创建时间':'Время создания',
  },
  '线下店铺套餐内容': {
    '备注':'Название пакета',
    '有效期':'Описание срока действия',
    '使用时间':'Инструкции по использованию времени',
    '使用规则':'Инструкции по использованию правил',
    '套餐logo':'Содержание картинки',
    '创建时间':'Время создания',
    '是否需要预约':'Нужно ли бронировать',
    '是否需要添加人员':'Нужно ли добавлять людей',
  },
  '线下店铺套餐详情': {
    '商品套餐':'Пакеты товаров',
    '套餐主标题':'Основной заголовок пакета',
    '套餐副标题':'Подзаголовок пакета',
    '套餐详情价格':'Цена пакета',
    '排序':'Сортировка',
    '创建时间':'Время создания',
    '套餐名称':'Название пакета',
    '手机号':'Номер мобильного телефона',
    '姓名':'Имя',
  },

  '优惠券类型':{
    '店铺ID':'ID магазина',
    '优惠券名称':'Название купона',
    '发放面额':'Выдача номиналов',
    '发放数量':'Количество выпущенных',
    '每人最大领取个数 0无限制':'Максимальное количество получателей на человека 0 без ограничений',
    '满多少元使用':'Сколько юаней используется',
    '领取人会员等级':'Уровень участника',
    '使用范围':'Диапазон использования',
    '业务ID，与type合并使用，如type为1，全场可用，为2，分类id，以此类推':'Идентификатор бизнеса, объединенный с типом, например, тип 1, доступный на всей площадке, 2, классификационный идентификатор и т. Д.',
    '有效日期开始时间':'Эффективное время начала',
    '有效日期结束时间':'Эффективное время окончания',
    '优惠券状态':'Состояние',
    '是否允许首页显示0不显示1显示':'Разрешить ли домашняя страница Отображение 0 Не показывает 1',
    '优惠券详细信息':'Сведения о купоне',
    '是否新人优惠券':'Является ли новый купон',
    '创建时间':'Время создания',
    '修改时间':'Время изменения',
    '创建人':'Создатель',
    '修改人':'Изменить лицо',
    '逻辑删除标记 1:删除,0:未删除':'Логическое удаление метки 1: Удалить, 0: Не удалить',
    '备注':'Примечания',
    '店铺':'Магазин',
  },
  '秒杀时间轴':{
    '主键id':'Первичный ключ id',
    '秒杀开始时间':'Время начала шипа',
    '秒杀结束时间':'Время окончания шипа',
    '是否显示 0：否 1：是':'Показывает ли 0: Нет 1: Да',
    '活动名称':'Название мероприятия',
    '创建时间':'Время создания',
    '修改时间':'Время изменения',
    '创建人':'Создатель',
    '修改人':'Изменить лицо',
    '逻辑删除标记 1:删除,0:未删除':'Логическое удаление метки 1: Удалить, 0: Не удалить',
    '备注':'Примечания',
  },
  '秒杀库存':{
    '主键id':'Первичный ключ id',
    '时间轴id':'Временная шкала',
    '商品id':'Товар id',
    '商品名称':'Название товара',
    '秒杀价格':'Спайк цена',
    '商品现价':'Текущая цена товара',
    '已经秒杀数量':'Количество шипов',
    '商品图片':'Фотографии товаров',
    '秒杀商品库存':'Спайк товарный инвентарь',
    '店铺id':'Магазин id',
    '店铺名字':'Название магазина',
    '状态':'Состояние',
    '秒杀是否完成0未完成 1已抢完':'Завершен ли шип 0',
    '拒绝原因':'Аудиторские заключения',
    '秒杀开始时间':'Время начала шипа',
    '秒杀结束时间':'Время окончания шипа',
    '最大购买数量':'Максимальное количество покупок',
    '创建时间':'Время создания',
    '修改时间':'Время изменения',
    '创建人':'Создатель',
    '修改人':'Изменить лицо',
    '逻辑删除标记 1:删除,0:未删除':'Логическое удаление метки 1: Удалить, 0: Не удалить',
    '备注':'Примечания',
  },
  '商家退货地址':{
    '商家退货地址id':'Адрес возврата продавца id',
    '店铺id':'Магазин id',
    '收货人名字':'Имя грузополучателя',
    '收货人手机号码':'Номер мобильного телефона получателя',
    '收货人座机':'Грузополучатель',
    '邮政编码':'Почтовый индекс',
    '省id':'Провинции id',
    '省':'Провинция',
    '城市id':'Город id',
    '城市':'Города',
    '区id':'Район id',
    '区':'Район',
    '具体地址':'Конкретный адрес',
    '默认地址':'Является ли это по умолчанию',
    '状态':'Доступно ли',
    '更新时间':'Время обновления',
    '创建时间':'Время создания',
  },
  '用户标签':{
    '标签id':'Теги id',
    '标签名称':'Название этикетки',
    '创建时间':'Время создания',
    '修改时间':'Время изменения',
    '创建人':'Создатель',
    '修改人':'Изменить лицо',
    '备注':'Примечания',
  },
  '拼团活动商品':{
    '主键id':'Ключ ID',
    '店铺id':'Магазин ID',
    '商品id':'Товары id',
    '活动名称':'Название мероприятия',
    '拼团有效期':'Срок действия группы',
    '成团人数':'Количество участников',
    '商品名称':'Название товара',
    '拼团价格':'Цены группы',
    '原价':'Первоначальная цена',
    '商品图片':'Изображения товаров',
    '状态': 'Статус',
    '拒绝原因':'Причины отказа',
    '拼团开始时间':'Время начала группы',
    '拼团结束时间':'Время окончания группы',
    '最大购买数量':'Максимальное количество покупок',
    '完成拼团数量':'Количество завершенных групп',
    '总团数量':'Общее число миссий',
    '创建者':'Основатель',
    '创建时间':'Время создания',
    '修改人':'Изменитель',
    '更新时间':'Время обновления',
    '逻辑删除标记':'Логическое удаление меток',
    '租户ID':'租户ID',
    '备注':'Примечания',
    '版本号':'Номер версии',
  },
  '砍价活动配置':{
    'id':'id',
    '店铺id':'Магазин ID',
    '活动名称':'Название мероприятия',
    '活动开始时间':'Время начала мероприятия',
    '活动结束时间':'Время окончания мероприятия',
    '商品id':'Товары id',
    '商品图片':'Изображения товаров',
    '商品名称':'Название товара',
    '商品原价':'Первоначальная стоимость товаров',
    '审核状态':'Статус аудита',
    '审核意见':'Заключение ревизоров',
    '多少人参与砍价即可成功':'Сколько человек будет участвовать в торгах',
    '砍价活动价格':'Цены на деятельность по обесценению',
    '该活动完成砍价数量':'Количество завершенных торгов',
    '砍价有效期':'Срок действия скидок',
    '创建者':'Основатель',
    '创建时间':'Время создания',
    '修改人':'Изменитель',
    '更新时间':'Время обновления',
    '逻辑删除标记':'Логическое удаление меток',
    '租户ID':'Идентификатор арендатора',
    '备注':'Примечания',
    '版本号':'Номер версии',
  },
  '预售活动商品配置':{
    'id':'id',
    '店铺id':'Магазин ID',
    '活动名称':'Название мероприятия',
    '活动开始时间':'Время начала мероприятия',
    '活动结束时间':'Время окончания мероприятия',
    '商品id':'Товары id',
    '商品图片':'Изображения товаров',
    '商品名称':'Название товара',
    '商品原价':'Первоначальная стоимость товаров',
    '预售商品售卖价格':'Предпродажная цена',
    '支付定金价格':'Выплата задатка',
    '支付尾款价格':'Конечная цена платежа',
    '审核状态':'Статус аудита',
    '审核意见':'Заключение ревизоров',
    '总完成数量':'Общее количество выполненных работ',
    '尾款付款开始时间':'Дата начала платежа',
    '创建者':'Основатель',
    '创建时间':'Время создания',
    '修改人':'Изменитель',
    '更新时间':'Время обновления',
    '逻辑删除标记':'Логическое удаление меток',
    '租户ID':'Идентификатор арендатора',
    '备注':'Примечания',
    '版本号':'Номер версии',
    '流程说明':'Описание процесса',
  },
  '抽奖活动奖池配置':{
    'id':'id',
    '名称':'Имя',
    '图片':'Фотографии',
    '奖励类型':'Вид вознаграждения',
    '奖励金额':'Сумма вознаграждения',
    '描述':'Описание',
    '创建者':'Основатель',
    '创建时间':'Время создания',
    '修改人':'Изменитель',
    '更新时间':'Время обновления',
    '逻辑删除标记':'Логическое удаление меток',
    '租户ID':'Идентификатор арендатора',
    '备注':'Примечания',
    '版本号':'Номер версии',
    '流程说明':'Описание процесса',
    '排序':'Сортировка',
    '优惠券':'Купоны',
  },
  '抽奖活动中奖记录':{
    'id':'id',
    '抽奖活动id':'Соревнования ID',
    '中奖信息文案':'Выигранный информационный текст',
    '奖励类型':'Вид вознаграждения',
    '奖励金额':'Сумма вознаграждения',
    '商品id':'Товары id',
    '商品名称':'Название товара',
    '商品图片':'Изображения товаров',
    '邮寄状态':'Статус почты',
    '申请邮寄地址':'Почтовый адрес заявки',
    '姓名':'Имя',
    '手机号':'Номер телефона',
    '发货方式':'Способ доставки',
    '详细地址':'Подробный адрес',
    '物流公司id':'Логистическая компания ID',
    '物流公司名称':'Название логистической компании',
    '寄件人手机号,':'Номер телефона отправителя,',
    '物流编号':'Логистический номер',
    '优惠券id':'Купоны ID',
    '优惠券名称':'Название купона',
    '创建者':'Основатель',
    '创建时间':'Время создания',
    '修改人':'Изменитель',
    '更新时间':'Время обновления',
    '逻辑删除标记':'Логическое удаление меток',
    '租户ID':'Идентификатор арендатора',
    '备注':'Примечания',
    '版本号':'Номер версии',
    '抽奖详情':'Лотерея Подробнее',
    '抽奖信息':'Информация о лотерее',
    '抽奖人昵称':'Прозвище победителя',
    '抽奖人手机号':'Номер телефона победителя.',
    '抽奖人头像':'Изображение лауреата',
  },
  '审核状态':{
    '待审核':'Подлежит рассмотрению',
    '审核失败':'Ошибка аудита',
    '审核成功':'Проверка прошла успешно',
    '过期未审核':'Срок годности не проверен',
    '活动已结束':'Описание процесса',
  },
  '奖励类型':{
    '谢谢惠顾':'Спасибо.',
    '平台币':'Платформа',
    '商品':'Товары',
    '优惠券':'Купоны',
  },
  '邮寄状态':{
    '待申请邮寄':'Отправка по заявке',
    '待邮寄':'Подлежит отправке по почте',
    '已邮寄':'Отправлено по почте',
  },
}